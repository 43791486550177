import React, { useState, useEffect } from 'react';
import './UserModal.css';

const UserModal = ({ role, isOpen, onClose, onSave, initialData }) => {
  const [userData, setUserData] = useState({
    username: '',
    password: '',
    role: 'customer',
  });

  useEffect(() => {
    if (initialData) {
      setUserData({ ...initialData, password: '' });
    } else {
      setUserData({
        username: '',
        password: '',
        role: 'customer',
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSave = () => {
    onSave(userData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className='modal-backdrop'>
      <div className='modal-content'>
        <button className='close-button' onClick={onClose}>
          X
        </button>
        <div className='form-group'>
          <label>Username</label>
          <input
            type='text'
            name='username'
            value={userData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <label>Password</label>
          <input
            type='password'
            name='password'
            value={userData.password}
            onChange={handleChange}
            required={!initialData}
          />
        </div>
        <div className='form-group'>
          <label>Role</label>
          <select
            name='role'
            value={userData.role}
            onChange={handleChange}
            required
          >
            <option value='admin'>Admin</option>
            <option value='customer'>Customer</option>
          </select>
        </div>
        <button className='save-button' onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default UserModal;
