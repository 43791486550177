import React, { useRef, useEffect } from 'react';

const ResizableSegment = ({ image, duration, index, onResize, isLast }) => {
    const segmentRef = useRef(null);
    const handleRef = useRef(null);
    const startX = useRef(0);
    const startWidth = useRef(0);

    useEffect(() => {
        const handleMouseDown = (e) => {
            e.preventDefault(); // Prevent text selection
            startX.current = e.clientX;
            startWidth.current = segmentRef.current.offsetWidth;
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        };

        const handleMouseMove = (e) => {
            e.preventDefault(); // Prevent text selection
            const deltaX = e.clientX - startX.current;
            onResize(deltaX, index, startWidth.current);
        };

        const handleMouseUp = (e) => {
            e.preventDefault(); // Prevent text selection
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        const handle = handleRef.current;
        if (handle) {
            handle.addEventListener('mousedown', handleMouseDown);
        }

        return () => {
            if (handle) {
                handle.removeEventListener('mousedown', handleMouseDown);
            }
        };
    }, [index, onResize]);

    return (
        <div
            ref={segmentRef}
            className="timeline-segment"
            style={{ flexGrow: duration, backgroundColor: getColor(index) }}
        >
            <img src={image} alt="Thumbnail" className="thumbnail" />
            <div className="duration-label">{duration}s</div>
            {!isLast && (
                <span ref={handleRef} className="resize-handle">
                    <span className="arrow left-arrow">&#9664;</span>
                    <span className="arrow right-arrow">&#9654;</span>
                </span>
            )}
        </div>
    );
};

const getColor = (index) => {
    const colors = ['#ff7f7f', '#7fbfff', '#7fff7f', '#ffff7f', '#bf7fff', '#ffbf7f', '#ff7fbf'];
    return colors[index % colors.length];
};

export default ResizableSegment;
