import React from 'react';
import './LandingScreen.css';

const LandingScreen = ({
  screens,
  onConnect,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  return (
    <div className='landing-screen'>
      <h2 className='title'>Select a Screen to Connect</h2>
      <div className='screens-container'>
        {screens.map((screen, index) => (
          <div key={index} className='screen-card'>
            <h3 className='screen-title'>{screen.name}</h3>
            <p className='screen-description'>{screen.description}</p>
            <p className='screen-ip'>{screen.ip}</p>
            <button
              className='connect-button'
              onClick={() => onConnect(screen)}
            >
              Connect
            </button>
            <button className='update-button' onClick={() => onUpdate(screen)}>
              Edit
            </button>
            <button className='delete-button' onClick={() => onDelete(screen)}>
              Delete
            </button>
          </div>
        ))}
        <div className='screen-card add-screen'>
          <button className='add-screen-button' onClick={onCreate}>
            Add Screen +
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;
