import React, { useState, useEffect } from 'react';
import './ScreenModal.css';

const ScreenModal = ({ isOpen, onClose, onSave, initialData }) => {
  const [screenData, setScreenData] = useState({
    name: '',
    description: '',
    ip: '',
  });

  useEffect(() => {
    if (initialData) {
      setScreenData(initialData);
    } else {
      setScreenData({ name: '', description: '', ip: '' });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setScreenData({ ...screenData, [name]: value });
  };

  const handleSave = () => {
    if (!screenData.name || !screenData.description || !screenData.ip) {
      alert('Please fill in all the fields.');
      return;
    }
    onSave(screenData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className='modal-backdrop'>
      <div className='modal-content'>
        <button className='close-button' onClick={onClose}>
          X
        </button>
        <div className='form-group'>
          <label>Screen Name</label>
          <input
            type='text'
            name='name'
            value={screenData.name}
            onChange={handleChange}
          />
        </div>
        <div className='form-group'>
          <label>Description</label>
          <input
            type='text'
            name='description'
            value={screenData.description}
            onChange={handleChange}
          />
        </div>
        <div className='form-group'>
          <label>IP</label>
          <input
            type='text'
            name='ip'
            value={screenData.ip}
            onChange={handleChange}
          />
        </div>
        <button className='save-button' onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ScreenModal;
