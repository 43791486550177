import React, { useRef } from 'react';
import ResizableSegment from './ResizableSegment';

const Timeline = ({
  role,
  images,
  durations,
  onDurationsChange,
  totalDuration,
}) => {
  const timelineRef = useRef(null);

  const handleResize = (deltaX, index, startWidth) => {
    const parentWidth = timelineRef.current.offsetWidth;
    const secondWidth = parentWidth / totalDuration;
    const durationChange = deltaX / secondWidth;
    let newDurations = [...durations];

    if (index < images.length - 1) {
      newDurations[index] = Math.max(newDurations[index] + durationChange, 0.1); // Minimum 0.1s to avoid 0s
      newDurations[index + 1] = Math.max(
        newDurations[index + 1] - durationChange,
        0.1
      );

      // Ensure the sum of newDurations is totalDuration seconds
      const totalAdjustedDuration = newDurations.reduce((a, b) => a + b, 0);
      const scalingFactor = totalDuration / totalAdjustedDuration;
      newDurations = newDurations.map((d) =>
        parseFloat((d * scalingFactor).toFixed(1))
      );

      onDurationsChange(newDurations);
    }
  };

  return (
    <div className='timeline-container'>
      <div className='timeline' ref={timelineRef}>
        {images.map((image, index) => (
          <ResizableSegment
            key={index}
            image={image}
            duration={durations[index]}
            index={index}
            onResize={handleResize}
            isLast={index === images.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default Timeline;
