import React, { useState, useEffect } from 'react';
import './App.css';
import Timeline from './Timeline';
import RightSidebar from './RightSidebar';
import Preview from './Preview';
import ScheduleTimeline from './ScheduleTimeline';
import api from './services/axios';
import LandingScreen from './pages/LandingScreen';
import ScreenModal from './components/ScreenModal';
import UserModal from './components/UserModal';

const App = () => {
  const [screens, setScreens] = useState([]);
  const [serverIp, setServerIp] = useState(null);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [view, setView] = useState('landing'); // Initially set to 'landing' for admin
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isImageReplaceModalOpen, setIsImageReplaceModalOpen] = useState(false);
  const [isImageDeleteConfirmOpen, setIsImageDeleteConfirmOpen] =
    useState(false);
  const [isRotationsModalOpen, setIsRotationsModalOpen] = useState(false);
  const [imageToReplaceIndex, setImageToReplaceIndex] = useState(null);
  const [imageToDeleteIndex, setImageToDeleteIndex] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(null); // 'admin' or 'user'

  const [liveFeedTimestamp, setLiveFeedTimestamp] = useState(null);
  const [liveFeedStatus, setLiveFeedStatus] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [initialSlotIndex, setInitialSlotIndex] = useState(0);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const secondsInDay = 86400;
  const timePerSlot = 10;

  const calculateViewsPerSlot = (slots) => {
    const totalWeight = slots.reduce(
      (acc, slot) => acc + 1 / (slot.rotations || 1),
      0
    );
    const viewsPerDay = secondsInDay / timePerSlot;
    return viewsPerDay / totalWeight;
  };

  const calculateSlotViews = (slot, totalViewsPerSlot) => {
    const slotWeight = 1 / (slot.rotations || 1);
    return Math.floor(totalViewsPerSlot * slotWeight);
  };

  const numberOfViewsPerSlot = calculateViewsPerSlot(slots);
  const numberOfSlots = slots.length;

  const fetchScreens = async () => {
    try {
      const response = await api.get('/api/screen/me');
      setScreens(response.data);
    } catch (error) {
      console.error('Error fetching screens:', error);
      alert('Error fetching screens.');
    }
  };

  const fetchSlots = async () => {
    try {
      let response;
      if (role === 'admin') response = await api.get('/api/slot');
      else if (role === 'customer') response = await api.get('/api/slot/me');
      setSlots(response.data);
    } catch (error) {
      console.error('Error fetching slots:', error);
      return [];
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/api/user');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    if (role) {
      fetchSlots();
      if (role === 'admin') {
        fetchScreens();
        fetchUsers();
      } else if (role === 'customer') setView('dashboard');
    }
  }, [role]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const user = JSON.parse(localStorage.getItem('user'));

    if (accessToken && refreshToken && user) {
      setIsAuthenticated(true);
      setRole(user.role);
    }
  }, []);

  const handleEditClick = (slot) => {
    setSelectedSlot(slot);
    setView('edit');
  };

  const handleFormChange = (e, field) => {
    const updatedSlot = {
      ...selectedSlot,
      customerInfo: { ...selectedSlot.customerInfo, [field]: e.target.value },
    };
    setSelectedSlot(updatedSlot);
  };

  const handleDurationChange = (e) => {
    const duration = parseInt(e.target.value, 10);
    if (!isNaN(duration) && selectedSlot) {
      const numImages = selectedSlot.images.length;
      const newDurations =
        numImages > 0
          ? Array(numImages).fill(duration / numImages)
          : [duration];
      const updatedSlot = {
        ...selectedSlot,
        totalDuration: duration,
        durations: newDurations,
      };
      setSelectedSlot(updatedSlot);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch('https://api.clearskydigital.ca/upload', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    return data.url;
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files).slice(0, 3);
    const validFiles = [];

    if (selectedSlot.images.length + files.length > 3) {
      alert('You can only upload up to 3 images per slot.');
      return;
    }

    for (const file of files) {
      const image = await getImageDimensions(file);
      if (image.width === 1200 && image.height === 600) {
        validFiles.push(file);
      } else {
        alert(
          `Image must be 1200px by 600px. ${file.name} has dimensions ${image.width}px by ${image.height}px.`
        );
      }
    }

    if (validFiles.length === 0) {
      return;
    }

    try {
      const imageUrls = await Promise.all(validFiles.map(uploadImage));

      const numImages = selectedSlot.images.length + imageUrls.length;
      const totalDuration = selectedSlot.totalDuration || 10;
      const newDurations = Array(numImages).fill(totalDuration / numImages);

      const updatedSlot = {
        ...selectedSlot,
        images: [...selectedSlot.images, ...imageUrls],
        durations: newDurations,
      };

      setSelectedSlot(updatedSlot);
      updateSlot(updatedSlot);
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  const handleRotationsChange = (e) => {
    const rotations = parseInt(e.target.value, 10);
    if (!isNaN(rotations) && selectedSlot) {
      const updatedSlot = { ...selectedSlot, rotations };
      setSelectedSlot(updatedSlot);
    }
  };

  const handleReplaceImage = async (e) => {
    const file = e.target.files[0];
    if (file && selectedSlot) {
      const image = await getImageDimensions(file);
      if (image.width !== 1200 || image.height !== 600) {
        alert(
          `Image must be 1200px by 600px. ${file.name} has dimensions ${image.width}px by ${image.height}px.`
        );
        return;
      }

      const imageUrl = await uploadImage(file);
      const updatedImages = [...selectedSlot.images];
      updatedImages[imageToReplaceIndex] = imageUrl;

      const numImages = updatedImages.length;
      const totalDuration = selectedSlot.totalDuration || 10;
      const newDurations = Array(numImages).fill(totalDuration / numImages);

      const updatedSlot = {
        ...selectedSlot,
        images: updatedImages,
        durations: newDurations,
      };

      setSelectedSlot(updatedSlot);
      updateSlot(updatedSlot);
      setIsImageReplaceModalOpen(false);
    }
  };

  const handleDeleteImage = () => {
    if (selectedSlot) {
      const updatedImages = selectedSlot.images
        ? selectedSlot.images.filter((_, index) => index !== imageToDeleteIndex)
        : [];
      const updatedDurations = selectedSlot.durations
        ? selectedSlot.durations.filter(
            (_, index) => index !== imageToDeleteIndex
          )
        : [];
      const updatedSlot = {
        ...selectedSlot,
        images: updatedImages,
        durations: updatedDurations,
      };
      setSelectedSlot(updatedSlot);
      updateSlot(updatedSlot);
      setIsImageDeleteConfirmOpen(false);
    }
  };

  const openImageReplaceModal = (index) => {
    setImageToReplaceIndex(index);
    setIsImageReplaceModalOpen(true);
  };

  const openImageDeleteConfirm = (index) => {
    setImageToDeleteIndex(index);
    setIsImageDeleteConfirmOpen(true);
  };

  const openRotationsModal = (slot) => {
    setSelectedSlot(slot);
    setIsRotationsModalOpen(true);
  };

  const updateSlot = async (updatedSlot) => {
    try {
      const selectedUser = users.find(
        (user) => user.username === updatedSlot.customerInfo.name
      );

      const endpoint = role === 'admin' ? '/api/slot' : '/api/slot/me';

      await api.patch(`${endpoint}/${updatedSlot.id}`, {
        images: updatedSlot.images,
        rotations: updatedSlot.rotations,
        durations: updatedSlot.durations,
        totalDuration: updatedSlot.totalDuration,
        userId: selectedUser ? selectedUser.id : null,
        company: updatedSlot.company,
        contact: updatedSlot.contact,
      });
      const updatedSlots = slots.map((slot) =>
        slot.id === updatedSlot.id ? updatedSlot : slot
      );
      setSlots(updatedSlots);
    } catch (error) {
      console.error('Error updating slot:', error);
      alert('Error updating slot');
    }
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    if (selectedSlot) {
      updateSlot(selectedSlot);
      setView('dashboard');
    }
  };

  const handleAddSlot = async () => {
    const newSlotNumber = slots.length + 1;
    const newSlot = {
      name: `Slot ${newSlotNumber}`,
      rotations: 1,
      durations: [10],
      totalDuration: 10,
    };

    try {
      const response = await api.post('/api/slot', newSlot);
      const addedSlot = response.data.slot;
      setSlots([...slots, addedSlot]);
    } catch (error) {
      console.error('Error adding slot:', error);
      alert('Error adding slot');
    }
  };

  const handleDeleteSlot = async () => {
    try {
      await api.delete(`/api/slot/${selectedSlot.id}`);
      const updatedSlots = slots.filter((slot) => slot.id !== selectedSlot.id);
      setSlots(updatedSlots);
      setSelectedSlot(null);
      setView('dashboard');
      setIsDeleteConfirmOpen(false);
    } catch (error) {
      console.error('Error deleting slot:', error);
      alert('Error deleting slot');
    }
  };

  const handleDurationsChange = (newDurations) => {
    if (selectedSlot) {
      const updatedSlot = { ...selectedSlot, durations: newDurations };
      setSelectedSlot(updatedSlot);
      updateSlot(updatedSlot);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/auth', { username, password });
      const user = response.data.user;
      localStorage.setItem('user', JSON.stringify(user));
      setIsAuthenticated(true);
      setRole(user.role);
    } catch (error) {
      alert('Invalid credentials');
    }
  };

  const handleLogout = async () => {
    try {
      await api.post('/api/auth/logout');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      setIsAuthenticated(false);
      setRole(null);
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Error logging out');
    }
  };

  const handleConnect = (screen) => {
    setServerIp(screen.ip);
    setView('dashboard');
  };

  const handleCreateScreen = () => {
    setModalData(null);
    setIsModalOpen(true);
  };

  const handleUpdateScreen = (screen) => {
    setModalData(screen);
    setIsModalOpen(true);
  };

  const handleDeleteScreen = async (screen) => {
    try {
      await api.delete(`/api/screen/me/${screen.id}`);
      const updatedScreens = screens.filter((s) => s.id !== screen.id);
      setScreens(updatedScreens);
    } catch (error) {
      console.error('Error deleting screen:', error);
      alert('Error deleting screen');
    }
  };

  const handleSaveScreen = async (screenData) => {
    try {
      let updatedScreens;
      if (modalData) {
        const response = await api.patch(
          `/api/screen/me/${modalData.id}`,
          screenData
        );
        updatedScreens = screens.map((screen) =>
          screen.id === modalData.id ? response.data : screen
        );
      } else {
        const response = await api.post('/api/screen', screenData);
        updatedScreens = [...screens, response.data.screen];
      }
      setScreens(updatedScreens);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving screen data:', error);
      alert('Error saving screen data');
    }
  };

  const handleCreateUser = () => {
    setSelectedUser(null);
    setIsUserModalOpen(true);
  };

  const handleUpdateUser = (user) => {
    setSelectedUser(user);
    setIsUserModalOpen(true);
  };

  const handleDeleteUser = async (user) => {
    try {
      await api.delete(`/api/user/${user.id}`);
      const updatedUsers = users.filter((u) => u.id !== user.id);
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Error deleting user');
    }
  };

  const handleSaveUser = async (userData) => {
    try {
      let updatedUsers;
      if (selectedUser) {
        const response = await api.patch(
          `/api/user/${selectedUser.id}`,
          userData
        );
        updatedUsers = users.map((user) =>
          user.id === selectedUser.id ? response.data : user
        );
      } else {
        const response = await api.post('/api/user', userData);
        updatedUsers = [...users, response.data.user];
      }
      setUsers(updatedUsers);
      setIsUserModalOpen(false);
    } catch (error) {
      console.error('Error saving user data:', error);
      alert('Error saving user data');
    }
  };

  const renderMainContent = () => {
    if (view === 'landing') {
      return (
        <LandingScreen
          screens={screens}
          onConnect={handleConnect}
          onCreate={handleCreateScreen}
          onUpdate={handleUpdateScreen}
          onDelete={handleDeleteScreen}
        />
      );
    }

    if (view === 'dashboard') {
      return (
        <div id='main-content'>
          <h2>Dashboard</h2>
          <div className='slots-container'>
            {slots.map((slot) => (
              <div
                key={slot.id}
                className={`slot-rectangle ${
                  slot.images.length === 0 ? 'inactive' : ''
                }`}
              >
                <h3>
                  {slot.name}{' '}
                  {slot.customerInfo.company &&
                    `(${slot.customerInfo.company})`}
                </h3>
                <div className='slot-images'>
                  {slot.images.map((imgSrc, imgIndex) => (
                    <div key={imgIndex} className='slot-image-container'>
                      <img
                        src={imgSrc}
                        alt={`Slot ${slot.id}`}
                        className='slot-image'
                        onClick={() => {
                          setSelectedSlot(slot);
                          openImageReplaceModal(imgIndex);
                        }}
                      />
                      <button
                        className='delete-image-button'
                        onClick={() => {
                          setSelectedSlot(slot);
                          openImageDeleteConfirm(imgIndex);
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
                <div className='actions'>
                  <button
                    className='edit-button'
                    onClick={() => {
                      setSelectedSlot(slot);
                      handleEditClick(slot);
                    }}
                  >
                    Edit
                  </button>
                </div>
                <div
                  className={`rotations-badge ${
                    slot.rotations ? '' : 'undefined-badge'
                  }`}
                  onClick={() => openRotationsModal(slot)}
                >
                  {`1/${slot.rotations || 1}`}
                </div>
                <p className='slot-duration'>
                  Duration: {slot.totalDuration || 10}s
                </p>
                <p className='slot-views'>
                  {slot.images.length === 0
                    ? 'Inactive'
                    : `Views: ${calculateSlotViews(
                        slot,
                        numberOfViewsPerSlot
                      )}`}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    } else if (view === 'edit' && selectedSlot) {
      return (
        <div id='main-content'>
          <h2>
            Edit {selectedSlot.name}{' '}
            {selectedSlot.customerInfo.company &&
              `(${selectedSlot.customerInfo.company})`}
          </h2>
          <form onSubmit={handleSaveChanges}>
            <div className='form-group'>
              <label>
                Name:
                {role === 'admin' ? (
                  <select
                    value={selectedSlot.customerInfo.name}
                    onChange={(e) => handleFormChange(e, 'name')}
                  >
                    <option value=''>Select a user</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.username}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type='text'
                    value={selectedSlot.customerInfo.name}
                    onChange={(e) => handleFormChange(e, 'name')}
                    readOnly
                  />
                )}
              </label>
            </div>
            <div className='form-group'>
              <label>
                Company:
                <input
                  type='text'
                  value={selectedSlot.customerInfo.company}
                  onChange={(e) => handleFormChange(e, 'company')}
                />
              </label>
            </div>
            <div className='form-group'>
              <label>
                Contact Info:
                <input
                  type='text'
                  value={selectedSlot.customerInfo.contact}
                  onChange={(e) => handleFormChange(e, 'contact')}
                />
              </label>
            </div>
            {role === 'admin' && (
              <div className='form-group'>
                <label>
                  Total Duration (seconds):
                  <input
                    type='number'
                    value={selectedSlot.totalDuration || 10}
                    onChange={handleDurationChange}
                    min='1'
                    readOnly={role === 'customer'}
                  />
                </label>
              </div>
            )}
            <div className='form-group'>
              <label>
                Gallery:
                <input
                  type='file'
                  multiple
                  accept='image/*'
                  onChange={handleImageUpload}
                />
              </label>
              <div className='gallery'>
                {selectedSlot.images.map((imgSrc, imgIndex) => (
                  <div key={imgIndex} className='gallery-image-container'>
                    <img
                      src={imgSrc}
                      alt='Uploaded'
                      className='gallery-image'
                      onClick={() => openImageReplaceModal(imgIndex)}
                    />
                    <button
                      className='delete-image-button'
                      onClick={() => openImageDeleteConfirm(imgIndex)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {role === 'admin' && (
              <>
                <h3>Timeline</h3>
                <Timeline
                  role={role}
                  images={selectedSlot.images}
                  durations={selectedSlot.durations}
                  onDurationsChange={handleDurationsChange}
                  totalDuration={selectedSlot.totalDuration || 10}
                />
                <div className='form-group'>
                  <label>
                    Played once every
                    <input
                      type='number'
                      value={selectedSlot.rotations || 1}
                      onChange={handleRotationsChange}
                      min='1'
                      readOnly={role === 'customer'}
                    />
                    rotations
                  </label>
                </div>
              </>
            )}
            <button type='submit' className='save-button'>
              Save Changes
            </button>
            {role === 'admin' && (
              <button
                type='button'
                className='delete-button'
                onClick={() => setIsDeleteConfirmOpen(true)}
              >
                Delete Slot
              </button>
            )}
          </form>
          <Preview
            images={selectedSlot.images}
            durations={selectedSlot.durations}
          />
        </div>
      );
    } else if (view === 'schedule') {
      return (
        <div id='main-content'>
          <h2>Schedule</h2>
          {liveFeedTimestamp && (
            <p>Live Feed Last Updated: {liveFeedTimestamp}</p>
          )}
          {liveFeedStatus ? (
            <ScheduleTimeline
              slots={slots}
              startTime={startTime}
              initialSlotIndex={initialSlotIndex}
              initialImageIndex={initialImageIndex}
            />
          ) : (
            <p>Live feed must be connected to view schedule.</p>
          )}
        </div>
      );
    } else if (view === 'user-management') {
      return (
        <div className='user-management-container'>
          <div className='user-management-header'>
            <h2>User Management</h2>
            <button className='add-user-button' onClick={handleCreateUser}>
              Add User
            </button>
          </div>
          <table className='user-table'>
            <thead>
              <tr>
                <th>Username</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td className='actions'>
                    <button
                      className='edit-button'
                      onClick={() => handleUpdateUser(user)}
                    >
                      Edit
                    </button>
                    <button
                      className='delete-button'
                      onClick={() => handleDeleteUser(user)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  const renderDeleteConfirm = () => {
    if (!isDeleteConfirmOpen) return null;

    return (
      <div className='modal'>
        <div className='modal-content'>
          <h3>Are you sure you want to delete this slot?</h3>
          <button onClick={handleDeleteSlot}>Yes</button>
          <button onClick={() => setIsDeleteConfirmOpen(false)}>No</button>
        </div>
      </div>
    );
  };

  const renderImageReplaceModal = () => {
    if (!isImageReplaceModalOpen) return null;

    return (
      <div className='modal'>
        <div className='modal-content'>
          <h3>Replace Image</h3>
          <input type='file' accept='image/*' onChange={handleReplaceImage} />
          <button onClick={() => setIsImageReplaceModalOpen(false)}>
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const renderImageDeleteConfirm = () => {
    if (!isImageDeleteConfirmOpen) return null;

    return (
      <div className='modal'>
        <div className='modal-content'>
          <h3>Are you sure you want to delete this image?</h3>
          <button onClick={handleDeleteImage}>Yes</button>
          <button onClick={() => setIsImageDeleteConfirmOpen(false)}>No</button>
        </div>
      </div>
    );
  };

  const renderRotationsModal = () => {
    if (!isRotationsModalOpen) return null;

    return (
      <div className='modal'>
        <div className='modal-content'>
          <h3>Set Rotations</h3>
          <label>
            Played Once Every
            <input
              type='number'
              value={selectedSlot ? selectedSlot.rotations || 1 : ''}
              onChange={handleRotationsChange}
              min='1'
            />
            Rotations:
          </label>
          <button
            onClick={() => {
              updateSlot(selectedSlot);
              setIsRotationsModalOpen(false);
            }}
          >
            Save
          </button>
          <button onClick={() => setIsRotationsModalOpen(false)}>Cancel</button>
        </div>
      </div>
    );
  };

  const renderLoginForm = () => {
    return (
      <div className='login-container'>
        <div className='login-box'>
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type='submit' className='submit-button'>
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div id='root'>
      {!isAuthenticated ? (
        renderLoginForm()
      ) : (
        <>
          {view !== 'landing' && (
            <Sidebar
              slots={slots}
              numberOfSlots={numberOfSlots}
              onEditClick={handleEditClick}
              setView={setView}
              handleAddSlot={handleAddSlot}
              openImageReplaceModal={openImageReplaceModal}
              setSelectedSlot={setSelectedSlot}
              openRotationsModal={openRotationsModal}
              openImageDeleteConfirm={openImageDeleteConfirm}
              selectedSlot={selectedSlot}
              calculateSlotViews={calculateSlotViews}
              numberOfViewsPerSlot={numberOfViewsPerSlot}
              role={role}
            />
          )}
          {renderMainContent()}
          {renderDeleteConfirm()}
          {renderImageReplaceModal()}
          {renderImageDeleteConfirm()}
          {renderRotationsModal()}
          {role === 'admin' && view !== 'landing' && (
            <RightSidebar
              serverIp={serverIp}
              setView={setView}
              slots={slots}
              setLiveFeedTimestamp={setLiveFeedTimestamp}
              setLiveFeedStatus={setLiveFeedStatus}
              setStartTime={setStartTime}
              setInitialSlotIndex={setInitialSlotIndex}
              setInitialImageIndex={setInitialImageIndex}
            />
          )}
          <ScreenModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSave={handleSaveScreen}
            initialData={modalData}
          />
          <UserModal
            role={role}
            isOpen={isUserModalOpen}
            onClose={() => setIsUserModalOpen(false)}
            onSave={handleSaveUser}
            initialData={selectedUser}
          />
          <button className='logout-button' onClick={handleLogout}>
            Logout
          </button>
        </>
      )}
    </div>
  );
};

const Sidebar = ({
  slots,
  numberOfSlots,
  onEditClick,
  setView,
  handleAddSlot,
  openImageReplaceModal,
  setSelectedSlot,
  openRotationsModal,
  openImageDeleteConfirm,
  selectedSlot,
  calculateSlotViews,
  numberOfViewsPerSlot,
  role,
}) => {
  return (
    <div id='sidebar'>
      <div>
        <div
          className='header'
          onClick={() => setView('dashboard')}
          style={{ cursor: 'pointer' }}
        >
          Dashboard
        </div>
        <div
          className='header'
          onClick={() => setView('schedule')}
          style={{ cursor: 'pointer' }}
        >
          Schedule
        </div>
        {role === 'admin' && (
          <>
            <div
              className='header'
              onClick={() => setView('user-management')}
              style={{ cursor: 'pointer' }}
            >
              User Management
            </div>
            {slots.map((slot) => (
              <Slot
                key={slot.id}
                slot={slot}
                onEditClick={() => {
                  setSelectedSlot(slot);
                  onEditClick(slot);
                }}
                openImageReplaceModal={openImageReplaceModal}
                setSelectedSlot={setSelectedSlot}
                openRotationsModal={openRotationsModal}
                openImageDeleteConfirm={openImageDeleteConfirm}
                isSelected={selectedSlot && selectedSlot.id === slot.id}
                calculateSlotViews={calculateSlotViews}
                numberOfViewsPerSlot={numberOfViewsPerSlot}
              />
            ))}
          </>
        )}
      </div>
      {role === 'admin' && (
        <div className='sidebar-footer'>
          <p># of Slots: {numberOfSlots}</p>
          <button className='add-slot-button' onClick={handleAddSlot}>
            Add Slot
          </button>
        </div>
      )}
    </div>
  );
};

const Slot = ({
  slot,
  onEditClick,
  openImageReplaceModal,
  setSelectedSlot,
  openRotationsModal,
  openImageDeleteConfirm,
  isSelected,
  calculateSlotViews,
  numberOfViewsPerSlot,
}) => {
  return (
    <div
      className={`slot ${slot.images.length === 0 ? 'inactive' : ''} ${
        isSelected ? 'selected' : ''
      } ${slot.images.length > 0 ? 'active' : ''}`}
    >
      <h3>
        {slot.name}{' '}
        {slot.customerInfo.company && `(${slot.customerInfo.company})`}
      </h3>
      <div className='actions'>
        <button className='edit-button' onClick={onEditClick}>
          Edit
        </button>
      </div>
      <div className='slot-images'>
        {slot.images.map((imgSrc, imgIndex) => (
          <div key={imgIndex} className='slot-image-container'>
            <img
              src={imgSrc}
              alt='Placeholder'
              className='slot-image'
              onClick={() => {
                setSelectedSlot(slot);
                openImageReplaceModal(imgIndex);
              }}
            />
            <button
              className='delete-image-button'
              onClick={() => {
                setSelectedSlot(slot);
                openImageDeleteConfirm(imgIndex);
              }}
            >
              X
            </button>
          </div>
        ))}
      </div>
      <div
        className={`rotations-badge ${slot.rotations ? '' : 'undefined-badge'}`}
        onClick={() => openRotationsModal(slot)}
      >
        {`1/${slot.rotations || 1}`}
      </div>
      <p className='slot-duration'>Duration: {slot.totalDuration || 10}s</p>
      <p className='slot-views'>
        {slot.images.length === 0
          ? 'Inactive'
          : `Views: ${calculateSlotViews(slot, numberOfViewsPerSlot)}`}
      </p>
    </div>
  );
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// Helper function to get image dimensions
const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};

export default App;
