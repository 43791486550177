import React from 'react';
import './ScheduleTimeline.css'; // Import CSS for timeline styling

const generateTimeline = (slots, startTime, initialSlotIndex, initialImageIndex) => {
    const timeline = [];
    const secondsInHour = 3600;

    let currentTime = new Date(startTime);
    let currentSlotIndex = initialSlotIndex;
    let currentImageIndex = initialImageIndex;

    const activeSlots = slots.filter(slot => slot.images.length > 0);

    for (let hour = 0; hour < 24; hour++) {
        const hourlyTimeline = [];
        while (currentTime.getHours() === hour || hourlyTimeline.length === 0) {
            const slot = activeSlots[currentSlotIndex];
            const duration = slot.durations[currentImageIndex] || 10;
            const hourString = String(currentTime.getHours()).padStart(2, '0');
            const minuteString = String(currentTime.getMinutes()).padStart(2, '0');
            const secondString = String(currentTime.getSeconds()).padStart(2, '0');
            const timeString = `${hourString}:${minuteString}:${secondString}`;

            hourlyTimeline.push({
                time: timeString,
                slotId: slot.id,
                slotName: slot.name,
                color: getSlotColor(slot.id)
            });

            currentTime.setSeconds(currentTime.getSeconds() + duration);
            currentImageIndex = (currentImageIndex + 1) % slot.images.length;
            if (currentImageIndex === 0) {
                currentSlotIndex = (currentSlotIndex + 1) % activeSlots.length;
            }
        }
        timeline.push(hourlyTimeline);
    }

    return timeline;
};

const getSlotColor = (slotId) => {
    const colors = [
        '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#33FFD2',
        '#FFBB33', '#BB33FF', '#33FFBB', '#FF3333', '#33BBFF',
        '#DFFF33', '#FF33DF', '#33FF77', '#FF7733', '#33A1FF',
        '#FF5733', '#33FF57', '#3357FF', '#FF33A1'
    ];
    return colors[(slotId - 1) % colors.length];
};

const ScheduleTimeline = ({ slots, startTime, initialSlotIndex, initialImageIndex }) => {
    const timeline = generateTimeline(slots, startTime, initialSlotIndex, initialImageIndex);

    return (
        <div className="timeline-container">
            {timeline.map((hourlyTimeline, hour) => (
                <div key={hour} className="hourly-entries">
                    {hourlyTimeline.map((entry, index) => (
                        <div
                            key={index}
                            className="timeline-entry"
                            style={{ backgroundColor: entry.color }}
                            title={`Slot ${entry.slotId} at ${entry.time}`}
                        >
                            {entry.time} - {entry.slotName}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ScheduleTimeline;

