import React, { useState, useEffect } from 'react';

const RightSidebar = ({
  serverIp,
  setView,
  slots,
  setLiveFeedTimestamp,
  setLiveFeedStatus,
  setStartTime,
  setInitialSlotIndex,
  setInitialImageIndex,
}) => {
  const [liveFeed, setLiveFeed] = useState({
    slot: null,
    image: null,
    time: null,
    imageUrl: '',
  });
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [debouncedRequest, setDebouncedRequest] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const fetchLiveFeedData = async () => {
      if (debouncedRequest) return;

      setDebouncedRequest(true);
      setTimeout(() => setDebouncedRequest(false), 1000);

      const timestamp = Date.now();
      const username = 'rpiview';
      const password = 'ahYFV64xBMMBsRsrl52G01';

      try {
        const response = await fetch(`${serverIp}/current_image?${timestamp}`, {
          headers: {
            Authorization: 'Basic ' + btoa(`${username}:${password}`),
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.slot && data.image) {
            const currentTime = new Date();
            setLiveFeed({
              slot: data.slot,
              image: data.image,
              time: currentTime.toLocaleTimeString(),
              imageUrl: data.image_url,
            });
            setLastUpdateTime(Date.now());
            setLiveFeedTimestamp(currentTime.toLocaleTimeString());
            setStartTime(currentTime);
            setInitialSlotIndex(data.slot - 1);
            setInitialImageIndex(data.image - 1);
            setLiveFeedStatus(true);
            setIsPaused(false);
          } else {
            setLiveFeedStatus(false);
          }
        } else {
          console.error('Failed to fetch live feed data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching live feed data:', error);
      }
    };

    const interval = setInterval(fetchLiveFeedData, 1000);

    const timeoutCheck = setInterval(() => {
      if (lastUpdateTime && Date.now() - lastUpdateTime > 30000) {
        setLiveFeed({ slot: null, image: null, time: null, imageUrl: '' });
        setLiveFeedTimestamp(null);
        setLiveFeedStatus(false);
        setIsPaused(true);
      }
    }, 1000);

    fetchLiveFeedData();

    return () => {
      clearInterval(interval);
      clearInterval(timeoutCheck);
    };
  }, [
    lastUpdateTime,
    setLiveFeedTimestamp,
    debouncedRequest,
    setLiveFeedStatus,
    setStartTime,
    setInitialSlotIndex,
    setInitialImageIndex,
    serverIp,
  ]);

  const copyToClipboard = () => {
    const jsonConfig = JSON.stringify(slots, null, 2);
    navigator.clipboard
      .writeText(jsonConfig)
      .then(() => alert('Configuration copied to clipboard!'))
      .catch((err) => alert('Failed to copy: ', err));
  };

  const exportToFile = () => {
    const jsonConfig = JSON.stringify(slots, null, 2);
    const blob = new Blob([jsonConfig], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'config.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  const uploadConfig = async () => {
    const jsonConfig = JSON.stringify(slots, null, 2);
    const blob = new Blob([jsonConfig], { type: 'application/json' });
    const formData = new FormData();
    formData.append('file', blob, 'config.json');

    try {
      const response = await fetch(`${serverIp}/upload_config`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (data.status === 'success') {
        alert('File uploaded successfully');
      } else {
        alert('Failed to upload file: ' + data.message);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };

  return (
    <div id='right-sidebar'>
      <div className='right-sidebar-content'>
        <pre className='log-content'>{JSON.stringify(slots, null, 2)}</pre>
        <button onClick={copyToClipboard}>Copy to Clipboard</button>
        <button onClick={exportToFile}>Export to File</button>
        <button onClick={uploadConfig}>Upload Config</button>
      </div>
      <div className='right-sidebar-live-feed'>
        <h3>Live Feed</h3>
        {isPaused ? (
          <p>Live feed is paused. Waiting for updates...</p>
        ) : (
          <>
            <p>Slot: {liveFeed.slot}</p>
            <p>Image: {liveFeed.image}</p>
            <p>Time: {liveFeed.time}</p>
            {liveFeed.imageUrl && (
              <img
                src={liveFeed.imageUrl}
                alt='Current'
                style={{ maxWidth: '100%' }}
              />
            )}
          </>
        )}
      </div>
      <div className='server-settings'>
        <h3>Server Settings</h3>
        <p>Connected to: {serverIp}</p>
        <button onClick={() => setView('landing')}>Change Server</button>
      </div>
    </div>
  );
};

export default RightSidebar;
